import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { VatinFieldControl } from "components/forms/fields";

function InnField(props) {
  const [show, setShow] = useState(false);
  const { errors, disabled } = props;

  useEffect(() => setShow(true), [errors?.inn]);

  const handleError = (isShow) => {
    setShow(isShow);
  };

  return (
    <VatinFieldControl
      className={props.className}
      name="inn"
      control={props.formHook.control}
      errors={props.formHook.errors}
      label="ИНН(физ.лицо):"
      required={false}
      responseError={show ? errors?.inn?.messages[0] : ""}
      onShowError={handleError}
      lengthVatin={12}
      disabled={disabled}
    />
  );
}
const mapStateToProps = (state) => ({
  errors: state.users.responseError,
});
export default connect(mapStateToProps)(InnField);
