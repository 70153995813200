import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  setPropAcquiring,
  setPropAcquiringEdit,
} from "domain/actions/organizations/acquiring";
import { TextField } from "components/forms/fields";

function TimeLifePayment(props) {
  const [value, setValue] = useState(20);
  const [updateKey, forceUpdate] = useState(1);

  const setProp = !props.select
    ? props.setPropAcquiring
    : props.setPropAcquiringEdit;

  useEffect(() => {
    if (props.select) return setValue(props.select.extParams?.sessionTimeout);
    setValue(props.value);
  }, [props.value, props.select]);

  return (
    <TextField
      className={props.className}
      key={updateKey}
      label="Время жизни платежа(в днях)"
      value={value}
      onChange={(e) => setValue(e)}
      onBlur={(e) => {
        setProp("extParams", Number(e.target.value), "sessionTimeout");
        forceUpdate(updateKey + 1);
      }}
      responseError={value?.length > 0 ? "" : "Обязательно для заполнения!"}
      keyfilter="int"
    />
  );
}

const mapStateToProps = (state) => ({
  value: state.orgAcquiring.acquiring.extParams?.sessionTimeout,
  select: state.orgAcquiring.select,
});
export default connect(mapStateToProps, {
  setPropAcquiring,
  setPropAcquiringEdit,
})(TimeLifePayment);
