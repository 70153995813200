import React, { useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { FooterForm, BodyForm } from "./components";

function Content(props) {
  const { ...formHook } = useForm();

  const setValues = useCallback(
    (values) => {
      for (let key in values) {
        formHook.setValue(key, values[key]);

        if (key === "email") {
          if (values[key] === null) formHook.setValue(key, "");
          else formHook.setValue(key, values[key]);
        }
        if (key === "inn") {
          if (values[key] === null) formHook.setValue(key, "");
          else formHook.setValue(key, values[key]);
        }
      }
    },
    [formHook]
  );

  useEffect(() => {
    console.log(props.select);
    if (!!props.select) {
      setValues(props.select);
    } else {
      setValues({ roles: "ACCOUNT_MEMBER" });
    }
  }, []);

  return (
    <form className={props.className}>
      <BodyForm className="p-d-flex p-flex-wrap p-mb-2" formHook={formHook} />

      <FooterForm
        handleSubmit={formHook.handleSubmit}
        className="p-col-12 p-p-0 p-d-flex p-flex-wrap p-jc-between"
      />
    </form>
  );
}

const mapStateToProps = (state) => ({
  select: state.users.select,
  roles: state.users.roles,
});
export default connect(mapStateToProps)(Content);
