import {
  SET_PAYMENTS,
  REFRESH_PAYMENTS,
  SELECT_PAYMENTS,
  SHOW_PAYMENT,
} from "../../types/payments";

const initialState = {
  payments: [],
  select: null,
  show: false,
  refresh: false,
};

export default function paymentsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PAYMENTS:
      return {
        ...state,
        payments: action.payload,
      };
    case SELECT_PAYMENTS:
      return {
        ...state,
        select: action.payload,
      };
    case REFRESH_PAYMENTS:
      return {
        ...state,
        refresh: action.payload,
      };
    case SHOW_PAYMENT:
      return {
        ...state,
        show: action.payload,
      };
    default:
      return state;
  }
}
