export const SET_DATA_ACCOUNT = "SET_DATA_ACCOUNT";
export const SET_STATIC_TOKEN_ACCOUNT = "SET_STATIC_TOKEN_ACCOUNT";

export const SHOW_ERROR = "SHOW_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const CLEAR_SUCCESS = "CLEAR_SUCCESS";
export const SHOW_SUCCESS = "SHOW_SUCCESS";

export const SHOW_SIDEBAR = "SHOW_SIDEBAR";

export const PATHNAME_ROUTE = "PATHNAME_ROUTE";

export const SET_REGISTER = "SET_REGISTER";
export const CLEAR_REGISTER = "CLEAR_REGISTER";

export const LOGIN_AUTH = "LOGIN_AUTH";
export const LOGOUT_AUTH = "LOGOUT_AUTH";
export const FORGETPASS_AUTH = "FORGETPASS_AUTH";
export const LOGIN_ISAUTH = "LOGIN_ISAUTH";
export const LOADING_AUTH = "LOADING_AUTH";
export const COUNT_ERROR_TOKEN_AUTH = "COUNT_ERROR_TOKEN_AUTH";
export const NULL_ERROR_TOKEN_AUTH = "NULL_ERROR_TOKEN_AUTH";
export const SET_VISIBLE_UNAUTHORIZED = "SET_VISIBLE_UNAUTHORIZED";

export const CHECKHASH_WINDOW = "CHECKHASH_WINDOW";

export const SET_ORGANIZATIONS = "SET_ORGANIZATIONS";
export const UPDATE_ORGANIZATIONS = "UPDATE_ORGANIZATIONS";
export const POST_ORGANIZATION = "POST_ORGANIZATION";
export const SELECT_ORGANIZATIONS = "SELECT_ORGANIZATIONS";
export const BREAK_ORGANIZATIONS = "BREAK_ORGANIZATIONS";
export const INFO_ORGANIZATION = "INFO_ORGANIZATION";

export const SET_GROUPTAX = "SET_GROUPTAX"; // Группы СНО
export const SET_ENTITY = "SET_ENTITY"; // Тип организаций
export const SET_REQUISITES = "SET_REQUISITES"; // вся дополнительная информация необхдимая для заполнения опеределенных полей
export const SET_REQUISITES_TARIFFS = "SET_REQUISITES_TARIFFS";
export const SET_TRANSLATE_ROLES_REQUISITES = "SET_TRANSLATE_ROLES_REQUISITES";
export const SET_REQUISITESFROMSERVER = "SET_REQUISITESFROMSERVER";

export const SET_RECEIPTINFO_CREATERECEIPTS = "SET_RECEIPTINFO_CREATERECEIPTS";
export const SET_RECEIPTINFO_EXTERNAL_ID = "SET_RECEIPTINFO_EXTERNAL_ID";
export const SET_ORGANIZATION_CREATERECEIPTS =
  "SET_ORGANIZATION_CREATERECEIPTS";
export const SET_TYPE_CREATERECEIPTS = "SET_TYPE_CREATERECEIPTS";
export const SET_TAXATIONTYPE_CREATERECEIPTS =
  "SET_TAXATIONTYPE_CREATERECEIPTS";
export const SET_CASHBOX_CREATERECEIPTS = "SET_CASHBOX_CREATERECEIPTS";
export const SET_CLIENTINFO_CREATERECEIPTS = "SET_CLIENTINFO_CREATERECEIPTS";
export const SET_ELECTRONICALLY_CREATERECEIPTS =
  "SET_ELECTRONICALLY_CREATERECEIPTS";
export const SET_OPERATORINFO_CREATERECEIPTS =
  "SET_OPERATORINFO_CREATERECEIPTS";
export const SET_AGENTINFO_CREATERECEIPTS = "SET_AGENTINFO_CREATERECEIPTS";
export const SET_PLACE_CREATERECEIPTS = "SET_PLACE_CREATERECEIPTS";
export const SET_SUPPLIERINFO_CREATERECEIPTS =
  "SET_SUPPLIERINFO_CREATERECEIPTS";
export const SET_CORRECTINFO_CREATERECEIPTS = "SET_CORRECTINFO_CREATERECEIPTS";
export const SET_ITEMS_CREATERECEIPTS = "SET_ITEMS_CREATERECEIPTS";
export const DELETE_ITEMS_CREATERECEIPTS = "DELETE_ITEMS_CREATERECEIPTS";
export const SELECTED_VALUEPAYMENTS_CREATERECEIPTS =
  "SELECTED_VALUEPAYMENTS_CREATERECEIPTS";
export const SELECTED_SUMPAYMENTS_CREATERECEIPTS =
  "SELECTED_SUMPAYMENTS_CREATERECEIPTS";
export const DELETE_PAYMENTS_CREATERECEIPTS = "DELETE_PAYMENTS_CREATERECEIPTS";
export const SELECTED_VALUETAXES_CREATERECEIPTS =
  "SELECTED_VALUETAXES_CREATERECEIPTS";
export const SELECTED_SUMTAXES_CREATERECEIPTS =
  "SELECTED_SUMTAXES_CREATERECEIPTS";
export const SET_TAXES_CREATERECEIPTS = "SET_TAXES_CREATERECEIPTS";
export const DELETE_TAXES_CREATERECEIPTS = "DELETE_TAXES_CREATERECEIPTS";
export const SET_PAYMENTS_CREATERECEIPTS = "SET_PAYMENTS_CREATERECEIPTS";
export const CLEARE_ITEMS_CREATERECEIPTS = "CLEARE_ITEMS_CREATERECEIPTS";
export const CLEARE_RECEIPT_CREATERECEIPTS = "CLEARE_RECEIPT_CREATERECEIPTS";
export const CLEAR_RECEIPT_PAYMENT = "CLEAR_RECEIPT_PAYMENT";

export const SHOW_WINDOW_CREATERECEIPTS = "SHOW_WINDOW_CREATERECEIPTS";

export const ADD_ITEMS_CREATERECEIPTS = "ADD_ITEMS_CREATERECEIPTS";
export const SELECTED_ITEMS_CREATERECEIPTS = "SELECTED_ITEMS_CREATERECEIPTS";
export const UPDATE_ITEMS_CREATERECEIPTS = "UPDATE_ITEMS_CREATERECEIPTS";
export const UPDATE_SCHEMA_CREATERECEIPTS = "UPDATE_SCHEMA_CREATERECEIPTS";

export const SET_EQUIPMENTS = "SET_EQUIPMENTS";
export const SELECT_EQUIPMENTS = "SELECT_EQUIPMENTS";
export const SHOW_WINDOW__EQUIPMENTS = "SHOW_WINDOW__EQUIPMENTS";
export const BREAK_EQUIPMENTS = "BREAK_EQUIPMENTS";

export const SET_PAGINATION = "SET_PAGINATION";
export const SET_PAGINATION_EQUIPMENTS = "SET_PAGINATION_EQUIPMENTS";
export const TOGGLE_PROGRESS = "TOGGLE_PROGRESS";

export const SET_RESPONSE_FEEDBACK = "SET_RESPONSE_FEEDBACK";
export const ERROR_FEEDBACK = "ERROR_FEEDBACK";

export const COUNT_MESSAGE_NOT_READ = "COUNT_MESSAGE_NOT_READ";
export const COUNT_MESSAGE_WARNING = "COUNT_MESSAGE_WARNING";

export const UPDATE_TYPE_CREATERECEIPTS = "UPDATE_TYPE_CREATERECEIPTS";

export const CREATE_RECEIPT_LOADING = "CREATE_RECEIPT_LOADING";
