import {
  ADD_ITEMS_CREATERECEIPTS,
  SET_ITEMS_CREATERECEIPTS,
  SELECTED_ITEMS_CREATERECEIPTS,
  UPDATE_ITEMS_CREATERECEIPTS,
  DELETE_ITEMS_CREATERECEIPTS,
  SHOW_WINDOW_CREATERECEIPTS,
  UPDATE_SCHEMA_CREATERECEIPTS,
  UPDATE_TYPE_CREATERECEIPTS,
} from "../../../actionTypes";

export function addItem() {
  return async function thunk(dispatch, getState) {
    let item = { ...getState().createReceiptItems.schemaItem };
    let id = getState().createReceiptItems.id;

    item.id = id;

    dispatch({ type: ADD_ITEMS_CREATERECEIPTS, payload: item });
  };
}

export function updateType(type) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: UPDATE_TYPE_CREATERECEIPTS, payload: type });
  };
}

export function setItem(item) {
  return async function thunk(dispatch, getState) {
    const requisites = getState().requisites;

    const types = requisites.itemType.filter(
      (method) => method.eng === item.type
    );
    const taxes = requisites.tax.filter((tax) => tax.eng === item.tax.type);
    const methods = requisites.paymentMethod.filter(
      (method) => method.eng === item.paymentMethod
    );
    const objects = requisites.paymentObject.filter(
      (method) => method.eng === item.paymentObject
    );

    item.paymentMethod = methods.length ? methods[0] : null;
    item.paymentObject = objects.length ? objects[0] : null;
    item.type = types.length ? types[0] : null;
    item.tax = taxes.length ? taxes[0] : null;

    console.log(item);
    dispatch({ type: SET_ITEMS_CREATERECEIPTS, payload: item });
  };
}

export function updateItem(item) {
  return async function thunk(dispatch, getState) {
    let items = getState().createReceiptItems.items;
    let index = items.map((el) => el.id).indexOf(Number(item.id));

    item.id = Number(item.id);
    item.amount = recalcAmount(item);

    let data = getState().createReceiptItems.items.slice();
    data.splice(index, 1, item);

    dispatch({ type: UPDATE_ITEMS_CREATERECEIPTS, payload: data });
  };
}

export function updateField(key, value, id) {
  return async function thunk(dispatch, getState) {
    let items = getState().createReceiptItems.items.slice();
    let index = items.map((el) => el.id).indexOf(id);
    let item = items[index];
    item[key] = value;

    item.amount = recalcAmount(item);

    items.splice(index, 1, item);

    dispatch({ type: UPDATE_ITEMS_CREATERECEIPTS, payload: items });
  };
}

export function updateFieldSchema(key, value) {
  return async function thunk(dispatch, getState) {
    let item = getState().createReceiptItems.schemaItem;
    item[key] = value;

    dispatch({ type: UPDATE_SCHEMA_CREATERECEIPTS, payload: item });
  };
}

export function selectedItem(id) {
  return async function thunk(dispatch, getState) {
    let items = getState().createReceiptItems.items.slice();
    let item = items.filter((el) => el.id === id);

    item = item.length ? item[0] : null;

    dispatch({ type: SELECTED_ITEMS_CREATERECEIPTS, payload: item });
  };
}

export function deleteItem(id) {
  return async function thunk(dispatch, getState) {
    let items = getState().createReceiptItems.items.slice();
    let index = items.map((el) => el.id).indexOf(id);

    items.splice(index, 1);

    dispatch({ type: DELETE_ITEMS_CREATERECEIPTS, payload: items });
  };
}

export function toggleShow(show) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: SHOW_WINDOW_CREATERECEIPTS, payload: show });
  };
}

function recalcAmount(item) {
  return Number(Number(item.price * item.quantity).toFixed(2));
}
