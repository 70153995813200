import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Status } from "../components";
import {
  Cashier,
  Requisite,
  Buyer,
  Agent,
  Payment,
  Products,
  ErrorComponent,
  UrlPayment,
  Organization,
  SendPayment,
} from "./components";
import CancelButton from "components/btns/cancelBtn";
import { closeWinReceipt, refundReceipt } from "domain/actions/receipt";
// import { Label } from "components/based";
import { Chip } from "primereact/chip";
import { Button } from "primereact/button";

function Content(props) {
  const [acquiring, setAcquiring] = useState(null);
  const status = props.item.status;
  const error = props.item.trouble;
  const urlPayment = props.item.urlPayment;
  const parameters = props.item.taskJson.parameters;
  const isRefund = parameters.isOnlinePayment && parameters.type === "sell";
  const { closeWinReceipt } = props;

  useEffect(() => {
    const taskJson = props.item.taskJson?.parameters;
    const organization = props.item.organization;
    if (taskJson && organization && organization.organizationAcquiring) {
      const _filter = organization.organizationAcquiring.filter((i) => {
        return i.id === taskJson.organizationAcquiringId;
      });
      if (_filter.length > 0) {
        setAcquiring(_filter[0]);
      }
    }
  }, [props.item]);

  const handleRefund = () => {
    props.refundReceipt(props.item.uuid);
  };

  return (
    <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap">
      <div className="p-col-12 p-d-flex p-flex-wrap">
        <div className="p-col-12 p-md-6 p-xl-4">
          <Status status={status} className="p-col-12 p-md-6 p-xl-4 p-p-0" />
          {acquiring && (
            <div className="p-col-12 p-px-0">
              <div className="p-d-flex p-col-12 p-p-0 p-flex-column">
                {/* <Label value="Тип платежной системы" className="p-mr-4" /> */}
                <Chip
                  label={`${acquiring?.acquiring?.name} ${
                    acquiring?.comment
                      ? `(${acquiring.comment})`
                      : `(Комментарий отсутствует)`
                  }`}
                  className={`p-m-2 c-chip`}
                />
              </div>
            </div>
          )}
        </div>
        {urlPayment && (
          <div className="p-col-12 p-md-6 p-xl-4">
            <UrlPayment urlPayment={urlPayment} className="p-col-12 p-p-0" />
            <SendPayment className="p-col-12 p-p-0 p-mb-2 p-mt-1" />
          </div>
        )}
      </div>
      <div className="p-col-12 p-md-6 ">
        {error && (
          <ErrorComponent className="p-col-12 p-p-0 p-mb-2" item={props.item} />
        )}

        <Cashier className="p-col-12 p-p-0 p-mb-2" item={props.item} />
        <Requisite className="p-col-12 p-p-0 p-mb-2" item={props.item} />
        <Buyer className="p-col-12 p-p-0 p-mb-2" item={props.item} />
        <Agent className="p-col-12 p-p-0 p-mb-2" item={props.item} />
      </div>
      <div className="p-col-12 p-md-6">
        {/* <div className="p-d-none p-d-md-block" style={{ height: "38px" }}></div> */}
        <Organization className="p-col-12 p-p-0 p-mb-2" />
        <Products
          items={props.item.taskJson.parameters.items}
          className="p-col-12 p-p-0 p-mb-2"
        />
        <Payment className="p-col-12 p-p-0 p-mb-2" item={props.item} />
      </div>
      <div className="p-col-12 p-d-flex p-flex-wrap p-p-0 p-jc-between">
        <CancelButton
          className="p-col-12 p-md-5 p-xl-4"
          onShow={() => closeWinReceipt(false)}
        />
        {isRefund && (
          <div className="p-col-12 p-md-5 p-xl-4">
            <Button
              label="Возврат платежа"
              className="с-button-success p-col-12"
              style={{ height: "50px" }}
              onClick={handleRefund}
            />
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  item: state.receipts.select,
});
export default connect(mapStateToProps, { closeWinReceipt, refundReceipt })(
  Content
);
