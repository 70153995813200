import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setPropAcquiring } from "domain/actions/organizations/created";
import { Select } from "components/forms/fields";

function TypePaymentSystem(props) {
  const [value, setValue] = useState("");
  const [updateKey, forceUpdate] = useState(1);

  const setProp = props.setPropAcquiring;

  useEffect(() => {
    const item = props.listTypeSystem.filter((i) => +i.id === +props.value);
    setValue(item.length ? item[0] : null);
  }, [props.listTypeSystem, props.value, props.select]);

  return (
    <Select
      className={props.className}
      key={updateKey}
      options={props.listTypeSystem && props.listTypeSystem}
      label="Тип платежной системы"
      value={value}
      onChange={(e) => {
        if (!props.disabled) setProp("acquiringId", +e.id, props.index);
        if (e.name === "SberQR" || e.name === "PSB" || e.name === "Alfabank")
          setProp("extParams", e.name, props.index, "acqName");

        forceUpdate(updateKey + 1);
      }}
    />
  );
}

const mapStateToProps = (state) => ({
  listTypeSystem: state.requisites.acquiring,
});
export default connect(mapStateToProps, {
  setPropAcquiring,
})(TypePaymentSystem);
