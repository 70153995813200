import React from "react";
import styles from "../index.module.css";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { InputText } from "primereact/inputtext";
import { isINN } from "utils/rus.tax.codes.util";

export function VatinFieldControl({
  control,
  errors,
  className = "",
  name = "",
  label = "",
  keyfilter = null,
  defaultValue = "",
  required = false,
  lengthVatin = null,
  // validate = null,
  responseError = null,
  onShowError = (isShow) => {},
  disabled = false,
}) {
  let rules = {};

  if (required) {
    rules.required = "Обязательно для заполнения!";
  }
  if (required) {
    rules.validate = (value) => {
      const inn = value.replace(/[_]/gm, "");
      if (inn === "" && !required) return true;
      if (lengthVatin && inn.length !== +lengthVatin)
        return "Введенный ИНН не прошел проверку";

      if (!isINN(inn)) {
        return "Введенный ИНН не прошел проверку";
      }

      return true;
    };
  }

  return (
    <div className={className}>
      <label className={`${styles.label} p-pb-1`}>
        {label} {required && <span style={{ color: "red" }}>*</span>}
      </label>
      <Controller
        name={name}
        control={control}
        render={(props) => (
          <InputText
            value={props.value}
            onChange={(e) => {
              let number = e.target.value.replace(/[\D]+/g, "");
              if (number.length <= 12) {
                onShowError(false);
                props.onChange(number);
              }
            }}
            className={`${styles.borderBottom}`}
            keyfilter={keyfilter}
            disabled={disabled}
          />
        )}
        rules={rules}
        defaultValue={defaultValue}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        as={<p className="errorText">message</p>}
      />
      {!!responseError && (
        <small className="p-error p-d-block">{`${responseError}`}</small>
      )}
    </div>
  );
}
