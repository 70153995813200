import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import UrlPaymentsWindow from "../../createReceipt/windows/url.payments.window";

import styles from "../../createReceipt/styles.module.css";

import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";

import { LazySelect } from "components/forms/fields/select";

import { Select } from "components/forms/fields";

import mapDispatchToProps from "domain/actions/createReceipt.actions";

function CreatePayment({ states, organization, methods }) {
  const history = useHistory();

  const [paymentShow, setPaymentShow] = useState(false);
  const [urlPayment, setUrlPayment] = useState(null);
  const [system, setSystem] = useState(null);
  const [listAcquiring, setListAcquiring] = useState([]);
  const [updateSum, forceUpdateSum] = useState(1);
  const [disabledBtn, setDisabledBtn] = useState(false);

  useEffect(() => {
    methods.createExternalId();
    return () => methods.clearExternalId();
  }, [methods]);

  useEffect(() => {
    methods.getOrganizations(1).then((r) => {
      if (r.data?.result?.total === 1) {
        methods.setOrganization(r.data.result.data[0]);
      }
      return r.data?.result?.total <= 0 && history.push("/main/organizations");
    });
  }, [methods, history]);

  const setInfoStorage = methods.setInfoStorage;

  useEffect(() => {
    if (!!states.organizations.length && states.requisite !== null) {
      setInfoStorage();
    }
  }, [states.organizations, states.requisite, setInfoStorage]);

  useEffect(() => {
    let org = states.receipt.org;
    methods.setOnlinePay(true);

    if (org && org.quickReceiptParams && org.quickReceiptParams?.active) {
      const params = org.quickReceiptParams;
      methods.setClientInfo({ emailOrPhone: params.emailOrPhone });
    }
  }, [states.receipt.org, methods]);

  useEffect(() => {
    methods.setPaySystem(null);
    if (!organization || !organization.organizationAcquiring) return;

    let acquiring = organization.organizationAcquiring.map((i) => {
      let acquiring = { ...i.acquiring };
      acquiring.acquiringId = i.id;
      acquiring.name =
        acquiring.name +
        " " +
        (i.comment ? i.comment : "( Комментарий отсутствует )");
      return acquiring;
    });

    setListAcquiring(acquiring);
  }, [organization]);

  const onConfirm = (cb) => {
    methods.postPayment().then((r) => {
      // console.log(r)
      try {
        cb();
        if (r.data.result) {
          if (r.data.result?.url) {
            setUrlPayment(r.data.result?.urlPayment);
            setPaymentShow(true);
          } else {
            // methods.clearReceipt();
            return history.push("/main/payments");
          }
        }
      } catch (err) {
        cb();
        return;
      }
    });
  };

  const onConfirmPayment = () => {
    const cb = () => {
      setDisabledBtn(false);
    };
    setDisabledBtn(true);
    onConfirm(cb);
  };

  useEffect(() => {
    return () => methods.clearTemplates();
  }, [methods]);

  useEffect(() => {
    const item = listAcquiring.filter(
      (i) => +i.acquiringId === states.organizationAcquiringId
    );

    setSystem(item.length ? item[0] : null);
  }, [states.organizationAcquiringId, listAcquiring]);

  useEffect(() => {
    if (!listAcquiring.length) return;

    const org = organization;

    if (org && org.quickReceiptParams && org.quickReceiptParams?.active) {
      const params = org.quickReceiptParams;

      if (params.organizationAcquiringId) {
        methods.setOnlinePay(true);
        const item = listAcquiring.filter(
          (i) => +i.acquiringId === params.organizationAcquiringId
        );
        setSystem(item.length ? item[0] : null);

        if (item.length) methods.setPaySystem(item[0].acquiringId);
      }
    }
  }, [organization, listAcquiring]);

  return (
    <React.Fragment>
      <div>
        <div className={`p-col-12 p-d-flex p-flex-wrap p-jc-start p-mb-3`}>
          <LazySelect
            className="p-col-12 p-sm-6 p-md-3 p-d-flex p-flex-column p-jc-end"
            value={states.receipt.org}
            label="Выбор организации"
            optionLabel="name"
            options={
              states.receipt.org
                ? [states.receipt.org]
                : states.receipt.organizations
            }
            limit={7}
            onChange={(e) => {
              methods.setOrganization(e.value);
            }}
            getData={methods.getLazyOrganizations}
          />

          <div className="p-col-12 p-sm-6 p-md-3 p-d-flex p-flex-column">
            <label className={`${styles.font} p-pb-1`}>Адрес получателя</label>
            <InputText
              value={
                states.receipt.clientInfo
                  ? states.receipt.clientInfo.emailOrPhone
                  : ""
              }
              onChange={(e) =>
                methods.setClientInfo({ emailOrPhone: e.target.value })
              }
              className={`${styles.borderBottom}`}
              placeholder="Введите эл.почту"
            />
          </div>
        </div>

        <div className="p-col-12 p-d-flex p-flex-wrap">
          <Select
            label="Тип платежной системы"
            options={listAcquiring}
            className="p-col-12 p-md-6 p-xl-7 "
            value={system}
            onChange={(e) => {
              methods.setPaySystem(e.acquiringId);
            }}
          />

          <div className="p-col-12 p-md-4 p-d-flex p-flex-column p-fluid">
            <label className={`${styles.font} p-pb-1`}>Сумма</label>
            <InputNumber
              value={states.receipt.payments[0].sum}
              onChange={(e) => {
                methods.selectedSumPaymnet(e.value, 0);
                forceUpdateSum(updateSum + 1);
              }}
              mode="decimal"
              minFractionDigits={2}
              maxFractionDigits={2}
              className={`${styles.borderBottom} p-col-12 p-md-6 p-xl-5 p-p-0`}
              placeholder="00.00"
            />
          </div>
        </div>

        <div className="p-col-12 p-d-flex p-jc-end p-p-0">
          <div className="p-col-12 p-md-3">
            <Button
              id="button-confirm-payment"
              label="Сформировать"
              className="с-button-success"
              onClick={onConfirmPayment}
              disabled={disabledBtn}
            />
          </div>
        </div>
      </div>

      <UrlPaymentsWindow
        show={paymentShow}
        url={urlPayment}
        setShow={() => {
          setPaymentShow(false);
          setUrlPayment(null);
          history.push("/main/payments");
        }}
      />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    states: {
      user: state.users.current,
      requisite: state.requisites,
      organizations: state.organizations.organizations,
      organizationAcquiringId: state.createReceipt.organizationAcquiringId,
      infoOrganization: state.organizations.organizationById,
      receipt: state.createReceipt,
      receiptItems: state.createReceiptItems,
      wait: state.auth.isAuthenticated,
      template: state.receiptTemplates.current,
    },
    organization: state.createReceipt.org,
    type: state.createReceiptItems.type,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePayment);
