// import generateUUID from "utils/generateUUID";
import { formatReceiptEng } from "../funcs";

export default function getCreatedReceipt(data) {
  let cashbox = data.cashbox ? data.cashbox : null;

  let receipt = {
    orgId: data.org.id,
    externId: data.externId,
    receiptBody: data,
  };

  if (cashbox) receipt.cashboxId = cashbox.id;

  if (data.isOnlinePayment) {
    receipt.receiptBody.onlinePayment = {
      organizationAcquiringId: data.organizationAcquiringId,
      amount: {
        value: String(data.payments.reduce((i, c) => i + c.sum, 0)),
        currency: "RUB",
      },
      paymentMethodData: "bank_card",
      description: "",
    };

    if (data.paymentCallback)
      receipt.receiptBody.onlinePayment["paymentCallback"] =
        data.paymentCallback;
  }

  receipt.receiptBody.clientInfo = data.clientInfo;

  receipt.receiptBody.clientInfo = deleteStateNull({
    ...receipt.receiptBody.clientInfo,
  });
  receipt.receiptBody.supplierInfo = deleteStateNull({
    ...receipt.receiptBody.supplierInfo,
  });

  receipt.receiptBody.taxes.forEach((i) => (i.sum = +i.sum));

  if (!data.isOnlinePayment) delete receipt.receiptBody.onlinePayment;

  delete receipt.receiptBody.org;
  delete receipt.receiptBody.cashbox;

  receipt.receiptBody = deleteStateNull({ ...receipt.receiptBody });
  receipt.receiptBody = formatReceiptEng({ ...receipt.receiptBody });

  if (!cashbox) delete receipt.receiptBody.taxationType;

  return receipt;
}

export function getCreatedPayment(data) {
  let payment = {
    externId: data.externId,
    amount: {
      value: String(data.payments.reduce((i, c) => i + c.sum, 0)),
      currency: "RUB",
    },
    organizationAcquiringId: data.organizationAcquiringId,
    paymentMethodData: "bank_card",
    description: "",
    emailOrPhone: data.clientInfo.emailOrPhone,
  };

  if (data.paymentCallback) payment["paymentCallback"] = data.paymentCallback;

  return payment;
}

function deleteStateNull(obj) {
  if (Object.keys(obj).length <= 0) return null;

  for (let key in obj) {
    if (obj[key] === undefined || obj[key] === null || obj[key] === "") {
      delete obj[key];
    }

    if (typeof obj[key] === "object" && Object.keys(obj[key]).length === 0) {
      delete obj[key];
    }
  }

  return obj;
}
