import {
  SET_USERS,
  SELECT_USERS,
  SET_PAGINATION_USERS,
  SHOW_WINDOW_USERS,
  REFRESH_USERS,
  SET_ROLES_USER,
  RESPONSE_ERROR_USER,
} from "domain/types/users";
import { SHOW_ERROR } from "domain/actionTypes";
import { AMSController, ApiController } from "domain/controllers";
import { isAuth } from "domain/actions/auth.actions";

import { TOGGLE_PROGRESS } from "domain/actionTypes";
import getFilters from "controllers/getFilters";

const Client = new ApiController();
const AMS = new AMSController();

export function getUsers(page) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: TOGGLE_PROGRESS, payload: true });

    let params = { limit: 10, page: page };
    let filters = getFilters(getState, "userFilter");

    Object.assign(params, filters);

    return await AMS.get(`/accounts/members`, params)
      .then((response) => {
        let result = response.data;

        dispatch({ type: SET_USERS, payload: result.data });
        dispatch({ type: SET_PAGINATION_USERS, payload: result });
        dispatch({ type: TOGGLE_PROGRESS, payload: false });

        return true;
      })
      .catch((e) => dispatch({ type: TOGGLE_PROGRESS, payload: false }));
  };
}

export function getUserById(userId) {
  return async function thunk(dispatch, getState) {
    return await AMS.get(`/users/${userId}`)
      .then((response) => {
        const res = response.data;

        let user = res.user;

        if (res.roles.length > 0) {
          user.roles = res.roles[0];
        }

        if (res.scope) {
          user.scope = res.scope;
        }

        dispatch({ type: SELECT_USERS, payload: { user: user } });

        return true;
      })
      .catch((e) =>
        dispatch({ type: SHOW_ERROR, payload: e.error ? e.error : e.message })
      );
  };
}

export function getUsersRoles(user) {
  return async function thunk(dispatch, getState) {
    return await AMS.get(`/roles/user`)
      .then((r) => {
        const translate = getState().translate.roles;
        const roles = r.data.roles.map((i) => ({
          id: i.roleKey,
          name: translate[i.roleKey].name,
          value: i.roleKey,
        }));

        dispatch({ type: SET_ROLES_USER, payload: roles });
      })
      .catch((e) => {
        dispatch({ type: SHOW_ERROR, payload: e.error ? e.error : e.message });
        return false;
      });
  };
}

export function postUser(user) {
  return async function thunk(dispatch, getState) {
    let refresh = getState().users.refresh;

    if (user?.email?.length === 0) delete user.email;

    delete user.id;
    if (!user.phone) delete user.phone;
    if (!user.inn) delete user.inn;

    user.mobile = user.mobile.replace(/[()+-]/gm, "");

    if (!user.roles) {
      delete user.roles;
    } else {
      user.roles = [user.roles];
    }

    user.comment = "";

    return await AMS.post(`/accounts/invite`, user)
      .then((r) => {
        if (r.data.errors) {
          dispatch({ type: RESPONSE_ERROR_USER, payload: r.data.errors });
          return false;
        }
        dispatch({ type: REFRESH_USERS, payload: !refresh });

        return true;
      })
      .then(() => {
        dispatch(handleShowWindow(false));
        return dispatch(isAuth());
      })
      .catch((e) => {
        console.log(e.response);
        if (e.response.status === 403) {
          dispatch({
            type: SHOW_ERROR,
            payload: "У вас недостаточно прав на выполнение этой операции",
          });
          return false;
        }
        if (e.response.data?.errors)
          dispatch({
            type: RESPONSE_ERROR_USER,
            payload: e.response.data.errors,
          });
        else
          dispatch({
            type: SHOW_ERROR,
            payload: e.response.data.error ? e.response.data.error : e.message,
          });

        return false;
      });
  };
}

export function postRights(data) {
  return async function thunk(dispatch, getState) {
    let refresh = getState().users.refresh;

    return await AMS.post(`/userScopes`, data)
      .then((r) => {
        if (r.data.errors) {
          dispatch({ type: RESPONSE_ERROR_USER, payload: r.data.errors });
          return false;
        }
        dispatch({ type: REFRESH_USERS, payload: !refresh });

        return true;
      })
      .catch((e) => {
        if (e.response.status === 403) {
          dispatch({
            type: SHOW_ERROR,
            payload: "У вас недостаточно прав на выполнение этой операции",
          });
          return false;
        }
        dispatch({ type: SHOW_ERROR, payload: e.error ? e.error : e.message });
        return false;
      });
  };
}

export function putUser(user) {
  return async function thunk(dispatch, getState) {
    let refresh = getState().users.refresh;
    if (!user.phone) delete user.phone;
    if (user?.email?.length === 0) delete user.email;
    if (!user.inn) user.inn = null;
    delete user.password;
    delete user.confirmPassword;

    user.mobile = user.mobile.replace(/[()+-]/gm, "");

    if (!user.roles) {
      delete user.roles;
    } else {
      user.roles = [user.roles];
    }

    user.comment = "";

    return await AMS.put(`/users`, user)
      .then((r) => {
        if (r.data.errors) {
          dispatch({ type: RESPONSE_ERROR_USER, payload: r.data.errors });
          return false;
        }
        dispatch({ type: REFRESH_USERS, payload: !refresh });
        return true;
      })
      .then(() => {
        dispatch(handleShowWindow(false));
        return dispatch(isAuth());
      })
      .catch((e) => {
        console.log(
          e.response.data,
          e.response.data?.errors?.length,
          e.response.data?.errors
        );
        if (e.response.status === 403) {
          dispatch({
            type: SHOW_ERROR,
            payload: "У вас недостаточно прав на выполнение этой операции",
          });
          return false;
        }
        // dispatch({ type: SHOW_ERROR, payload: e.error ? e.error : e.message });
        if (e.response.data?.errors)
          dispatch({
            type: RESPONSE_ERROR_USER,
            payload: e.response.data.errors,
          });
        else
          dispatch({
            type: SHOW_ERROR,
            payload: e.response.data.error ? e.response.data.error : e.message,
          });

        return false;
      });
  };
}

export function deleteUser(id) {
  return async function thunk(dispatch, getState) {
    let refresh = getState().users.refresh;

    await Client.delete(`/user/${id}`)
      .then(() => dispatch({ type: REFRESH_USERS, payload: !refresh }))
      .catch((e) => {
        dispatch({ type: SHOW_ERROR, payload: e.error ? e.error : e.message });
        return false;
      });
  };
}

export function selectedUser(user) {
  return async function thunk(dispatch, getState) {
    return dispatch({
      type: SELECT_USERS,
      payload: { user: user },
    });
  };
}

export function handleShowWindow(isShow) {
  return async function thunk(dispatch, getState) {
    if (!isShow) {
      dispatch({ type: RESPONSE_ERROR_USER, payload: null });
      dispatch({ type: SELECT_USERS, payload: { user: null } });
    }

    return dispatch({
      type: SHOW_WINDOW_USERS,
      payload: isShow,
    });
  };
}

export function clearResponseError() {
  return async function thunk(dispatch, getState) {
    dispatch({ type: RESPONSE_ERROR_USER, payload: null });
  };
}
